const batchReports = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 15.00,4.00
 C 15.00,4.00 5.00,4.00 5.00,4.00
   5.00,4.00 5.00,20.00 5.00,20.00
   5.00,20.00 19.00,20.00 19.00,20.00
   19.00,20.00 19.00,8.00 19.00,8.00
   19.00,8.00 15.00,8.00 15.00,8.00
   15.00,8.00 15.00,4.00 15.00,4.00 Z
 M 3.00,2.99
 C 3.00,2.44 3.45,2.00 4.00,2.00
   4.00,2.00 16.00,2.00 16.00,2.00
   16.00,2.00 21.00,7.00 21.00,7.00
   21.00,7.00 21.00,20.99 21.00,20.99
   21.00,21.00 21.00,21.00 21.00,21.00
   21.00,21.55 20.56,22.00 20.01,22.00
   20.01,22.00 3.99,22.00 3.99,22.00
   3.45,22.00 3.00,21.55 3.00,21.01
   3.00,21.01 3.00,2.99 3.00,2.99 Z
 M 12.00,8.00
 C 12.00,8.00 12.00,12.00 12.00,12.00
   12.00,12.00 16.00,12.00 16.00,12.00
   16.00,14.21 14.21,16.00 12.00,16.00
   9.79,16.00 8.00,14.21 8.00,12.00
   8.00,9.79 9.79,8.00 12.00,8.00
   12.00,8.00 12.00,8.00 12.00,8.00 Z" />
  </g>
  </g>
`,
];

export default batchReports;
