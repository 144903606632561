const search = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 11.00,2.00
 C 15.97,2.00 20.00,6.03 20.00,11.00
   20.00,15.97 15.97,20.00 11.00,20.00
   6.03,20.00 2.00,15.97 2.00,11.00
   2.00,6.03 6.03,2.00 11.00,2.00 Z
 M 11.00,18.00
 C 14.87,18.00 18.00,14.87 18.00,11.00
   18.00,7.13 14.87,4.00 11.00,4.00
   7.13,4.00 4.00,7.13 4.00,11.00
   4.00,14.87 7.13,18.00 11.00,18.00 Z
 M 19.56,18.00
 C 19.56,18.00 22.39,20.83 22.39,20.83
   22.39,20.83 20.97,22.24 20.97,22.24
   20.97,22.24 18.14,19.41 18.14,19.41
   18.14,19.41 19.56,18.00 19.56,18.00
   19.56,18.00 19.56,18.00 19.56,18.00 Z" />
  </g>
  </g>
`,
];

export default search;
