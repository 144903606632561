const close = [
  '24 24',
  `
  <title></title>
  <g>
  <g>
  <path fill="var(--ci-primary-color, currentColor)" d="M 16.55,12.00
  C 16.55,12.00 23.37,5.18 23.37,5.18
    24.21,4.34 24.21,2.98 23.37,2.14
    23.37,2.14 21.86,0.63 21.86,0.63
    21.02,-0.21 19.66,-0.21 18.82,0.63
    18.82,0.63 12.00,7.45 12.00,7.45
    12.00,7.45 5.18,0.63 5.18,0.63
    4.34,-0.21 2.98,-0.21 2.14,0.63
    2.14,0.63 0.63,2.14 0.63,2.14
    -0.21,2.98 -0.21,4.34 0.63,5.18
    0.63,5.18 7.45,12.00 7.45,12.00
    7.45,12.00 0.63,18.82 0.63,18.82
    -0.21,19.66 -0.21,21.02 0.63,21.86
    0.63,21.86 2.14,23.37 2.14,23.37
    2.98,24.21 4.34,24.21 5.18,23.37
    5.18,23.37 12.00,16.55 12.00,16.55
    12.00,16.55 18.82,23.37 18.82,23.37
    19.66,24.21 21.02,24.21 21.86,23.37
    21.86,23.37 23.37,21.86 23.37,21.86
    24.21,21.02 24.21,19.66 23.37,18.82
    23.37,18.82 16.55,12.00 16.55,12.00 Z" class="ci-primary" />
    </g>
  </g>
`,
];

export default close;
