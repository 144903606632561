const dashboard = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 12.00,2.00
 C 17.52,2.00 22.00,6.48 22.00,12.00
   22.00,17.52 17.52,22.00 12.00,22.00
   6.48,22.00 2.00,17.52 2.00,12.00
   2.00,6.48 6.48,2.00 12.00,2.00 Z
 M 12.00,4.00
 C 7.58,4.00 4.00,7.58 4.00,12.00
   4.00,16.42 7.58,20.00 12.00,20.00
   16.42,20.00 20.00,16.42 20.00,12.00
   20.00,7.58 16.42,4.00 12.00,4.00 Z
 M 10.84,11.05
 C 10.59,11.35 10.13,12.34 11.02,13.12
   11.91,13.89 12.76,13.32 13.12,12.95
   13.48,12.66 16.53,8.39 16.74,7.99
   16.92,7.56 16.41,7.07 15.93,7.27
   15.58,7.47 11.07,10.72 10.84,11.05 Z
 M 17.50,11.00
 C 18.07,11.00 18.50,11.45 18.50,12.00
   18.50,12.55 18.05,13.00 17.50,13.00
   16.95,13.00 16.50,12.55 16.50,12.00
   16.50,11.45 16.93,11.00 17.50,11.00 Z
 M 6.50,11.00
 C 7.08,11.01 7.50,11.45 7.50,12.00
   7.50,12.55 7.05,13.00 6.50,13.00
   5.95,13.00 5.50,12.55 5.50,12.00
   5.50,11.45 5.92,10.99 6.50,11.00 Z
 M 9.11,8.04
 C 9.11,7.53 8.68,7.10 8.12,7.11
   7.55,7.11 7.12,7.63 7.11,8.04
   7.12,8.70 7.65,9.11 8.21,9.11
   8.77,9.10 9.11,8.57 9.11,8.04 Z
 M 12.00,5.50
 C 12.54,5.49 13.00,5.95 13.00,6.50
   13.00,7.05 12.55,7.50 12.00,7.50
   11.45,7.50 11.00,7.05 11.00,6.50
   11.00,5.95 11.46,5.51 12.00,5.50 Z" />
  </g>
  </g>
`,
];

export default dashboard;
