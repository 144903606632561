/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isAuthenticated } from './app/appSlice';
import Page500 from './views/pages/Page500';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authenticated = useSelector(isAuthenticated);
  return (
    <Route
      {...rest}
      render={(props) => (
        authenticated
          ? (
            <ErrorBoundary FallbackComponent={Page500} onReset={() => { /* we may reset the app state here so error doesn't happen again */ }}>
              <Component {...props} />
            </ErrorBoundary>
          )
          : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )}
    />
  );
};

PrivateRoute.defaultProps = {
  location: {
    hash: '',
    pathname: '/',
    search: '',
    state: undefined
  }
};

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.shape({
    key: PropTypes.string,
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object
  }),
};

export default PrivateRoute;
