const manuals = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 21.00,8.02
 C 21.00,8.02 21.00,21.02 21.00,21.02
   21.00,21.02 21.00,21.02 21.00,21.02
   21.00,21.57 20.56,22.02 20.01,22.02
   20.01,22.02 4.17,22.01 4.15,22.03
   3.61,22.03 3.05,21.61 3.00,21.05
   3.00,21.05 3.05,2.79 3.05,2.79
   3.14,2.41 3.45,2.02 4.00,2.02
   4.00,2.02 15.00,2.02 15.00,2.02
   15.00,2.02 21.00,8.02 21.00,8.02 Z
 M 19.00,9.00
 C 19.00,9.00 14.00,9.00 14.00,9.00
   14.00,9.00 14.00,4.00 14.00,4.00
   14.00,4.00 5.00,4.00 5.00,4.00
   5.00,4.00 5.00,20.00 5.00,20.00
   5.00,20.00 19.00,20.00 19.00,20.00
   19.00,20.00 19.00,9.00 19.00,9.00 Z
 M 8.00,7.00
 C 8.00,7.00 11.00,7.00 11.00,7.00
   11.00,7.00 11.00,9.00 11.00,9.00
   11.00,9.00 8.00,9.00 8.00,9.00
   8.00,9.00 8.00,7.00 8.00,7.00 Z
 M 8.00,11.00
 C 8.00,11.00 16.00,11.00 16.00,11.00
   16.00,11.00 16.00,13.00 16.00,13.00
   16.00,13.00 8.00,13.00 8.00,13.00
   8.00,13.00 8.00,11.00 8.00,11.00 Z
 M 8.00,15.00
 C 8.00,15.00 16.00,15.00 16.00,15.00
   16.00,15.00 16.00,17.00 16.00,17.00
   16.00,17.00 8.00,17.00 8.00,17.00
   8.00,17.00 8.00,15.00 8.00,15.00 Z" />
  </g>
  </g>
`,
];

export default manuals;
