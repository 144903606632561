const notificationLists = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 2.00,22.00
 C 2.00,17.58 5.58,14.00 10.00,14.00
   14.42,14.00 18.00,17.58 18.00,22.00
   18.00,22.00 16.00,22.00 16.00,22.00
   16.00,18.69 13.31,16.00 10.00,16.00
   6.69,16.00 4.00,18.69 4.00,22.00
   4.00,22.00 2.00,22.00 2.00,22.00 Z
 M 10.00,13.00
 C 6.69,13.00 4.00,10.31 4.00,7.00
   4.00,3.69 6.69,1.00 10.00,1.00
   13.31,1.00 16.00,3.69 16.00,7.00
   16.00,10.31 13.31,13.00 10.00,13.00 Z
 M 10.00,11.00
 C 12.21,11.00 14.00,9.21 14.00,7.00
   14.00,4.79 12.21,3.00 10.00,3.00
   7.79,3.00 6.00,4.79 6.00,7.00
   6.00,9.21 7.79,11.00 10.00,11.00 Z
 M 18.28,14.70
 C 21.06,15.95 23.00,18.75 23.00,22.00
   23.00,22.00 23.00,22.00 23.00,22.00
   23.00,22.00 21.00,22.00 21.00,22.00
   21.00,22.00 21.00,22.00 21.00,22.00
   21.00,19.56 19.55,17.47 17.47,16.53
   17.47,16.53 16.64,14.70 16.64,14.70
   16.64,14.70 18.28,14.70 18.28,14.70 Z
 M 16.49,3.42
 C 19.19,3.64 20.81,5.79 21.00,8.50
   21.00,8.50 21.00,8.50 21.00,8.50
   21.00,11.37 18.81,13.72 16.00,13.98
   16.00,13.98 16.00,11.97 16.00,11.97
   17.70,11.72 19.00,10.26 19.00,8.50
   19.00,7.12 18.20,5.93 17.04,5.36
   17.04,5.36 16.49,3.41 16.49,3.41
   16.49,3.41 16.49,3.42 16.49,3.42 Z" />
  </g>
  </g>
`,
];

export default notificationLists;
