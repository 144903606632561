import moment from 'moment-timezone';
import { getStorageItem } from './utils/ApiClient';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/en-ca';
import { consts } from './utils/config';

export default {
  name: 'detectMainCulture',

  lookup() {
    let userCulture = getStorageItem(consts.userdata)?.culture;
    if (!userCulture || userCulture === 'browser') {
      userCulture = (navigator.language || navigator.userLanguage)?.substring(0, 2);
    }
    moment.locale(userCulture);
    return userCulture;
  },

  cacheUserLanguage() {
    // not used
  },
};
