const show = [
  '24 17',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 23.13,7.95
 C 22.41,6.73 18.45,0.61 11.73,0.81
   5.50,0.96 1.90,6.30 0.93,7.95
   0.93,7.95 0.69,8.65 0.69,8.65
   0.90,9.07 1.00,9.27 1.00,9.27
   1.00,9.27 4.91,16.18 12.55,16.20
   18.44,15.89 22.03,10.87 23.00,9.22
   23.00,9.22 23.26,8.53 23.26,8.53
   23.26,8.53 23.13,7.95 23.13,7.95 Z
 M 12.27,14.00
 C 7.67,14.01 4.11,10.17 3.27,8.45
   4.33,7.19 6.96,3.12 12.00,2.94
   17.30,2.94 20.86,8.53 20.86,8.53
   19.52,10.28 16.78,13.89 12.27,14.00 Z
 M 12.03,4.65
 C 9.85,4.65 8.09,6.38 8.09,8.50
   8.09,10.62 9.85,12.35 12.03,12.35
   14.20,12.35 15.96,10.62 15.96,8.50
   15.96,6.38 14.20,4.65 12.03,4.65
   12.03,4.65 12.03,4.65 12.03,4.65 Z
 M 12.03,10.15
 C 11.09,10.15 10.34,9.41 10.34,8.50
   10.34,7.59 11.09,6.85 12.03,6.85
   12.96,6.85 13.71,7.59 13.71,8.50
   13.71,9.41 12.96,10.15 12.03,10.15
   12.03,10.15 12.03,10.15 12.03,10.15 Z" />
  </g>
  </g>
`,
];

export default show;
