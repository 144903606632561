import React from 'react';
import { useTranslation } from 'react-i18next';
import { CCol, CContainer, CRow, CLink } from '@coreui/react';

const Page500 = () => {
  const { t } = useTranslation('translation');
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer className="h-50">
        <CRow className="justify-content-center">
          <CCol md="6">
            <span className="clearfix">
              <h1 className="float-left display-3 mr-4">500</h1>
              <h4 className="pt-3">{t('profile.500Message')}</h4>
              <p className="text-muted float-left">{t('profile.500Message')}</p>
            </span>
            <CLink to="/">{t('routes.Home')}</CLink>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Page500;
