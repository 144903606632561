const marker = [
  '18 24',
  `
  <title></title>
  <g>
  <g style="var(--ci-primary-color, currentColor)">
 <path d="M 8.08,23.52
 C 1.26,13.64 0.00,12.63 0.00,9.00
   0.00,4.03 4.03,0.00 9.00,0.00
   13.97,0.00 18.00,4.03 18.00,9.00
   18.00,12.63 16.74,13.64 9.92,23.52
   9.48,24.16 8.52,24.16 8.08,23.52 Z
 M 9.00,12.75
 C 11.07,12.75 12.75,11.07 12.75,9.00
   12.75,6.93 11.07,5.25 9.00,5.25
   6.93,5.25 5.25,6.93 5.25,9.00
   5.25,11.07 6.93,12.75 9.00,12.75 Z" class="ci-primary" />
    </g>
    </g>
  `,
];

export default marker;
