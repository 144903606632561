const arrowLeft = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
  <path d="M 11.83,12.00
  C 11.83,12.00 14.66,14.83 14.66,14.83
    14.66,14.83 13.24,16.24 13.24,16.24
    13.24,16.24 9.00,12.00 9.00,12.00
    9.00,12.00 13.24,7.76 13.24,7.76
    13.24,7.76 14.66,9.17 14.66,9.17
    14.66,9.17 11.83,12.00 11.83,12.00 Z" />
    </g>
  </g>
`,
];

export default arrowLeft;
