const temperature = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 8.00,5.00
 C 8.00,2.79 9.79,1.00 12.00,1.00
   14.21,1.00 16.00,2.79 16.00,5.00
   16.00,5.00 16.00,10.25 16.00,10.25
   17.81,11.52 19.00,13.62 19.00,16.00
   19.00,19.87 15.87,23.00 12.00,23.00
   8.13,23.00 5.00,19.87 5.00,16.00
   5.00,13.62 6.19,11.52 8.00,10.25
   8.00,10.25 8.00,5.00 8.00,5.00 Z
 M 9.14,11.89
 C 7.85,12.80 7.00,14.30 7.00,16.00
   7.00,18.76 9.24,21.00 12.00,21.00
   14.76,21.00 17.00,18.76 17.00,16.00
   17.00,14.30 16.15,12.80 14.86,11.89
   14.86,11.89 14.00,11.30 14.00,11.30
   14.00,11.30 14.00,5.00 14.00,5.00
   14.00,3.90 13.10,3.00 12.00,3.00
   10.90,3.00 10.00,3.90 10.00,5.00
   10.00,5.00 10.00,11.30 10.00,11.30
   10.00,11.30 8.55,11.30 8.55,11.30
   8.55,11.30 9.14,11.89 9.14,11.89 Z
 M 10.63,11.30
 C 10.63,11.30 10.63,5.00 10.63,5.00
   10.63,5.00 12.63,5.00 12.63,5.00
   12.63,5.00 12.63,12.13 12.63,12.13
   14.38,12.55 15.68,14.13 15.68,16.01
   15.68,18.11 14.06,19.83 12.00,20.00
   12.00,20.00 12.00,20.00 12.00,20.00
   9.79,20.00 8.00,18.21 8.00,16.00
   8.00,14.14 9.27,12.57 11.00,12.13
   11.00,12.13 10.63,11.30 10.63,11.30 Z
 M 12.00,18.00
 C 13.10,18.00 14.00,17.10 14.00,16.00
   14.00,14.90 13.10,14.00 12.00,14.00
   10.90,14.00 10.00,14.90 10.00,16.00
   10.00,17.10 10.90,18.00 12.00,18.00
   12.00,18.00 12.00,18.00 12.00,18.00 Z" />
  </g>
  </g>
`,
];

export default temperature;
