const alarm = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 12.00,22.00
 C 7.03,22.00 3.00,17.97 3.00,13.00
   3.00,8.03 7.03,4.00 12.00,4.00
   16.97,4.00 21.00,8.03 21.00,13.00
   21.00,17.97 16.97,22.00 12.00,22.00
   12.00,22.00 12.00,22.00 12.00,22.00 Z
 M 12.00,20.00
 C 15.87,20.00 19.00,16.87 19.00,13.00
   19.00,9.13 15.87,6.00 12.00,6.00
   8.13,6.00 5.00,9.13 5.00,13.00
   5.00,16.87 8.13,20.00 12.00,20.00
   12.00,20.00 12.00,20.00 12.00,20.00 Z
 M 13.00,13.00
 C 13.00,13.00 16.00,13.00 16.00,13.00
   16.00,13.00 16.00,15.00 16.00,15.00
   16.00,15.00 11.00,15.00 11.00,15.00
   11.00,15.00 11.00,8.00 11.00,8.00
   11.00,8.00 13.00,8.00 13.00,8.00
   13.00,8.00 13.00,13.00 13.00,13.00 Z
 M 1.75,6.28
 C 1.75,6.28 5.28,2.75 5.28,2.75
   5.28,2.75 6.70,4.16 6.70,4.16
   6.70,4.16 3.16,7.70 3.16,7.70
   3.16,7.70 1.75,6.28 1.75,6.28 Z
 M 18.72,2.75
 C 18.72,2.75 22.25,6.28 22.25,6.28
   22.25,6.28 20.84,7.70 20.84,7.70
   20.84,7.70 17.30,4.16 17.30,4.16
   17.30,4.16 18.72,2.75 18.72,2.75
   18.72,2.75 18.72,2.75 18.72,2.75 Z" />
  </g>
  </g>
`,
];

export default alarm;
