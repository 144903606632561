const fleetManagement = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 11.00,14.00
 C 11.00,14.00 11.00,11.00 11.00,11.00
   11.00,11.00 13.00,11.00 13.00,11.00
   13.00,11.00 13.00,14.00 13.00,14.00
   13.00,14.00 18.00,14.00 18.00,14.00
   18.55,14.00 19.00,14.45 19.00,15.00
   19.00,15.00 19.00,21.00 19.00,21.00
   19.00,21.55 18.55,22.00 18.00,22.00
   18.00,22.00 6.00,22.00 6.00,22.00
   5.45,22.00 5.00,21.55 5.00,21.00
   5.00,21.00 5.00,15.00 5.00,15.00
   5.00,14.45 5.45,14.00 6.00,14.00
   6.00,14.00 11.00,14.00 11.00,14.00 Z
 M 2.51,8.84
 C 3.83,4.86 7.58,2.00 12.00,2.00
   16.42,2.00 20.17,4.86 21.49,8.84
   21.49,8.84 19.61,9.44 19.61,9.44
   18.55,6.26 15.55,3.97 12.02,3.97
   8.48,3.97 5.50,6.26 4.44,9.44
   4.44,9.44 2.51,8.84 2.51,8.84 Z
 M 6.31,10.05
 C 7.11,7.66 9.40,5.98 12.05,5.98
   14.70,5.98 16.86,7.70 17.66,10.08
   17.66,10.08 15.81,10.77 15.81,10.77
   15.28,9.18 13.81,8.00 12.05,8.00
   10.28,8.00 8.77,9.16 8.23,10.75
   8.23,10.75 6.31,10.05 6.31,10.05 Z
 M 7.00,16.00
 C 7.00,16.00 7.00,20.00 7.00,20.00
   7.00,20.00 17.00,20.00 17.00,20.00
   17.00,20.00 17.00,16.00 17.00,16.00
   17.00,16.00 7.00,16.00 7.00,16.00 Z" />
  </g>
  </g>
`,
];

export default fleetManagement;
