const userAdd = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 14.00,14.25
 C 14.00,14.25 14.00,16.34 14.00,16.34
   13.38,16.12 12.70,16.00 12.00,16.00
   8.69,16.00 6.00,18.69 6.00,22.00
   6.00,22.00 6.00,22.00 6.00,22.00
   6.00,22.00 4.00,22.00 4.00,22.00
   4.00,22.00 4.00,22.00 4.00,22.00
   4.00,17.58 7.58,14.00 12.00,14.00
   12.69,14.00 13.36,14.08 14.00,14.25
   14.00,14.25 14.00,14.25 14.00,14.25 Z
 M 12.00,13.00
 C 8.69,13.00 6.00,10.31 6.00,7.00
   6.00,3.69 8.69,1.00 12.00,1.00
   15.31,1.00 18.00,3.68 18.00,7.00
   18.00,10.31 15.31,13.00 12.00,13.00 Z
 M 12.00,11.00
 C 14.21,11.00 16.00,9.21 16.00,7.00
   16.00,4.79 14.21,3.00 12.00,3.00
   9.79,3.00 8.00,4.79 8.00,7.00
   8.00,9.21 9.79,11.00 12.00,11.00 Z
 M 18.00,17.00
 C 18.00,17.00 18.00,14.00 18.00,14.00
   18.00,14.00 20.00,14.00 20.00,14.00
   20.00,14.00 20.00,17.00 20.00,17.00
   20.00,17.00 23.00,17.00 23.00,17.00
   23.00,17.00 23.00,19.00 23.00,19.00
   23.00,19.00 20.00,19.00 20.00,19.00
   20.00,19.00 20.00,22.00 20.00,22.00
   20.00,22.00 18.00,22.00 18.00,22.00
   18.00,22.00 18.00,19.00 18.00,19.00
   18.00,19.00 15.00,19.00 15.00,19.00
   15.00,19.00 15.00,17.00 15.00,17.00
   15.00,17.00 18.00,17.00 18.00,17.00 Z" />
  </g>
  </g>
`,
];

export default userAdd;
