const gateway = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 6.12,20.09
 C 3.62,18.27 2.00,15.33 2.00,12.01
   2.00,12.01 2.00,12.00 2.00,12.00
   2.00,6.48 6.48,2.00 12.00,2.00
   17.52,2.00 22.00,6.48 22.00,12.00
   22.00,12.00 22.00,12.01 22.00,12.01
   22.00,15.33 20.38,18.27 17.89,20.09
   17.89,20.09 16.87,18.35 16.87,18.35
   18.77,16.89 20.00,14.59 20.00,12.00
   20.00,7.58 16.42,4.00 12.00,4.00
   7.58,4.00 4.00,7.58 4.00,12.00
   4.00,14.59 5.23,16.89 7.13,18.35
   7.13,18.35 6.12,20.09 6.12,20.09
   6.12,20.09 6.12,20.09 6.12,20.09 Z
 M 8.15,16.60
 C 6.84,15.50 6.00,13.85 6.00,12.00
   6.00,8.69 8.69,6.00 12.00,6.00
   15.31,6.00 18.00,8.69 18.00,12.00
   18.00,13.85 17.16,15.50 15.85,16.60
   15.85,16.60 14.82,14.84 14.82,14.84
   15.55,14.11 16.00,13.11 16.00,12.00
   16.00,9.79 14.21,8.00 12.00,8.00
   9.79,8.00 8.00,9.79 8.00,12.00
   8.00,13.11 8.45,14.11 9.18,14.84
   9.18,14.84 8.15,16.60 8.15,16.60
   8.15,16.60 8.15,16.60 8.15,16.60 Z
 M 11.00,13.00
 C 11.00,13.00 13.00,13.00 13.00,13.00
   13.00,13.00 14.00,22.00 14.00,22.00
   14.00,22.00 10.00,22.00 10.00,22.00
   10.00,22.00 11.00,13.00 11.00,13.00 Z" />
  </g>
  </g>
`,
];

export default gateway;
