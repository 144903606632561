import { freeSet } from '@coreui/icons';
import active from './active';
import alarm from './alarm';
import alarms from './alarms';
import alarmsHistory from './alarms-history';
import avatar from './avatar';
import arrowDown from './arrow-down';
import arrowLeft from './arrow-left';
import arrowRight from './arrow-right';
import arrowUp from './arrow-up';
import barChart from './bar-chart';
import batchReports from './batch-reports';
import calendar from './calendar';
import clientsManagement from './clients-management';
import close from './close';
import cog from './cog';
import dashboard from './dashboard';
import database from './database';
import datalogger from './datalogger';
import decommissioned from './decommissioned';
import disconnected from './disconnected';
import dots from './dots';
import download from './download';
import faq from './faq';
import filter from './filter';
import fleetManagement from './fleet-management';
import gateway from './gateway';
import hide from './hide';
import inactive from './inactive';
import info from './info';
import instrument from './instrument';
import lineChart from './line-chart';
import map from './map';
import marker from './marker';
import manuals from './manuals';
import note from './note';
import notificationLists from './notification-lists';
import plus from './plus';
import pressure from './pressure';
import search from './search';
import show from './show';
import siteManagement from './site-management';
import sort from './sort';
import supportRequest from './support-request';
import temperature from './temperature';
import userAdd from './user-add';
import userManagement from './user-management';
import warning from './warning';

export const icons = {
  active,
  alarm,
  alarms,
  alarmsHistory,
  avatar,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp,
  barChart,
  batchReports,
  calendar,
  clientsManagement,
  close,
  cog,
  dashboard,
  database,
  datalogger,
  decommissioned,
  disconnected,
  dots,
  download,
  faq,
  filter,
  fleetManagement,
  gateway,
  hide,
  inactive,
  info,
  instrument,
  lineChart,
  map,
  marker,
  manuals,
  note,
  notificationLists,
  plus,
  pressure,
  search,
  show,
  siteManagement,
  sort,
  supportRequest,
  temperature,
  userAdd,
  userManagement,
  warning,
  ...freeSet
};

export default icons;
