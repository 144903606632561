const info = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 12.00,22.00
 C 6.48,22.00 2.00,17.52 2.00,12.00
   2.00,6.48 6.48,2.00 12.00,2.00
   17.52,2.00 22.00,6.48 22.00,12.00
   22.00,17.52 17.52,22.00 12.00,22.00 Z
 M 11.00,11.00
 C 11.00,11.00 11.00,17.00 11.00,17.00
   11.00,17.00 13.00,17.00 13.00,17.00
   13.00,17.00 13.00,11.00 13.00,11.00
   13.00,11.00 11.00,11.00 11.00,11.00 Z
 M 11.00,7.00
 C 11.00,7.00 11.00,9.00 11.00,9.00
   11.00,9.00 13.00,9.00 13.00,9.00
   13.00,9.00 13.00,7.00 13.00,7.00
   13.00,7.00 11.00,7.00 11.00,7.00 Z" />
  </g>
  </g>
`,
];

export default info;
