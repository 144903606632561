const sort = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 19.64,14.18
 C 19.64,14.18 4.36,14.18 4.36,14.18
   4.07,14.18 3.81,14.29 3.60,14.51
   3.38,14.72 3.27,14.98 3.27,15.27
   3.27,15.57 3.38,15.82 3.60,16.04
   3.60,16.04 11.23,23.68 11.23,23.68
   11.45,23.89 11.70,24.00 12.00,24.00
   12.30,24.00 12.55,23.89 12.77,23.68
   12.77,23.68 20.40,16.04 20.40,16.04
   20.62,15.82 20.73,15.57 20.73,15.27
   20.73,14.98 20.62,14.72 20.40,14.51
   20.19,14.29 19.93,14.18 19.64,14.18 Z
 M 4.36,9.82
 C 4.36,9.82 19.64,9.82 19.64,9.82
   19.93,9.82 20.19,9.71 20.40,9.49
   20.62,9.28 20.73,9.02 20.73,8.73
   20.73,8.43 20.62,8.18 20.40,7.96
   20.40,7.96 12.77,0.32 12.77,0.32
   12.55,0.11 12.30,0.00 12.00,0.00
   11.70,0.00 11.45,0.11 11.23,0.32
   11.23,0.32 3.60,7.96 3.60,7.96
   3.38,8.18 3.27,8.43 3.27,8.73
   3.27,9.02 3.38,9.28 3.60,9.49
   3.81,9.71 4.07,9.82 4.36,9.82 Z" />
  </g>
  </g>
`,
];

export default sort;
