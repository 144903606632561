const plus = [
  '243 243',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 142.33,15.76
     C 142.33,15.76 99.67,15.76 99.67,15.76
       99.67,15.76 99.67,101.09 99.67,101.09
       99.67,101.09 14.33,101.09 14.33,101.09
       14.33,101.09 14.33,143.76 14.33,143.76
       14.33,143.76 99.67,143.76 99.67,143.76
       99.67,143.76 99.67,229.09 99.67,229.09
       99.67,229.09 142.33,229.09 142.33,229.09
       142.33,229.09 142.33,143.76 142.33,143.76
       142.33,143.76 227.67,143.76 227.67,143.76
       227.67,143.76 227.67,101.09 227.67,101.09
       227.67,101.09 142.33,101.09 142.33,101.09
       142.33,101.09 142.33,15.76 142.33,15.76 Z" />

  </g>
  </g>
`,
];

export default plus;
