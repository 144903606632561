import axios from 'axios';
import i18next from 'i18next';
import { consts, urls } from './config';
import baseurl from './apihost.json';

const { CancelToken } = axios;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.baseURL = baseurl.url || undefined;

const handleError = (error) => {
  if (error.response) {
    let errorMessage;
    switch (error.response.status) {
      case 200: errorMessage = error.response?.data?.messages?.join(', ') || i18next.t('messages.UnsuccessfulRequest'); break;
      case 204: errorMessage = error.response?.data?.messages?.join(', ') || i18next.t('messages.NoDataReceived'); break;
      case 400:
        if (error.response?.data?.name === 'InvalidCredentials') {
          errorMessage = i18next.t('login.LoginFailed');
        } else {
          errorMessage = error.response?.data?.messages?.join(', ') || i18next.t('messages.BadRequest');
        }
        break;
      case 401: errorMessage = i18next.t('messages.Unauthorized'); break;
      case 404: errorMessage = error.response?.data?.messages?.join(', ') || i18next.t('messages.NotFound'); break;
      case 403: errorMessage = i18next.t('messages.Forbidden'); break;
      case 406: errorMessage = i18next.t('messages.NotAcceptable'); break;
      case 423: errorMessage = i18next.t('messages.Locked'); break;
      case 500:
      case 502: errorMessage = i18next.t('messages.ServerError'); break;
      case 503: errorMessage = i18next.t('messages.ServiceUnavailable'); break;
      default: errorMessage = null;
    }
    return errorMessage;
  }

  if (error.request) return i18next.t('messages.NoResponse');

  return `${i18next.t('messages.GeneralError')} ${error.message}`;
};

const onError = (error, errorHandler) => {
  let errorMesage = handleError(error);
  let innerErrors = [];

  if (!error.response || !error.response.data) {
    innerErrors.push(error.message);
    if (errorHandler) {
      errorHandler(errorMesage, innerErrors);
      return null;
    }
    return { errorMesage, innerErrors };
  }

  const _errorData = error.response.data;

  if (_errorData && typeof _errorData === 'string') {
    innerErrors.push(_errorData);
  }

  if (_errorData && typeof _errorData === 'object') {
    if (_errorData.messages) {
      if (Array.isArray(_errorData.messages)) innerErrors = [...innerErrors, ..._errorData.messages];
      else innerErrors = [...innerErrors, ...Object.values(_errorData.messages)];
    }

    if (_errorData.errors) {
      if (Array.isArray(_errorData.errors)) innerErrors = [...innerErrors, ..._errorData.errors];
      else innerErrors = [...innerErrors, ...Object.values(_errorData.errors)];
    }

    if (_errorData.title) {
      errorMesage = _errorData.title;
    }
  }

  if (errorHandler) {
    errorHandler(errorMesage, innerErrors);
    return null;
  }

  return { errorMesage, innerErrors };
};

export const getStorageItem = (key) => {
  const data = sessionStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const setStorageItem = (key, data) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

export const getToken = () => {
  const auth = getStorageItem(consts.authentication);
  if (auth && auth.token) return `Bearer ${auth.token}`;
  return undefined;
};

const getRefreshToken = () => {
  const auth = getStorageItem(consts.authentication);
  if (auth && auth.refreshToken) return auth.refreshToken;
  return '';
};

const addSiteIdQuery = (url) => {
  const site = getStorageItem(consts.currentSite);
  if (!site) return url;
  if (url.includes('?')) return `${url}&siteId=${site.siteId}`;
  return `${url}?siteId=${site.siteId}`;
};

export const GetCancelTokenSource = () => CancelToken.source();

export const refreshAuthorisationAsync = async () => {
  const options = {
    method: 'POST',
    headers: { Authorization: getToken() },
    data: { token: getRefreshToken() },
    url: urls.signinrefresh
  };

  try {
    const result = await axios.request(options);
    setStorageItem(consts.authentication, result.data);
    return true;
  } catch (error) {
    console.error('Token refresh failed : ', error);
    return false;
  }
};

const doRequest = (options, hanlders) => {
  const _options = { ...options, url: addSiteIdQuery(options.url) };
  axios.request(_options)
    .then((result) => {
      if (hanlders.success) hanlders.success(result.data, result.status);
    })
    .catch((error) => {
      if (!hanlders.anonymous && error.response && error.response.status === 401) {
        refreshAuthorisationAsync()
          .then((ok) => {
            if (ok) {
              doRequest({ ...options, headers: { Authorization: getToken() } }, hanlders);
            } else {
              sessionStorage.clear();
              window.location.replace('/login');
            }
          });
        return;
      }
      if (axios.isCancel(error)) return;
      onError(error, hanlders.error);
    })
    .then(() => {
      if (hanlders.afterall) hanlders.afterall();
    });
};

export const GetRequest = (url, success, error, afterall, cancelToken, timeoutOverride, anonymous) => {
  const options = {
    headers: !anonymous && { Authorization: getToken() },
    method: 'GET',
    url,
    timeout: timeoutOverride || consts.timeout,
    cancelToken,
  };

  doRequest(options, { success, error, afterall, anonymous });
};

export const PostRequest = (url, data, success, error, afterall, cancelToken, timeoutOverride, anonymous) => {
  const options = {
    headers: !anonymous && { Authorization: getToken() },
    method: 'POST',
    url,
    data,
    cancelToken,
    timeout: timeoutOverride || consts.timeout
  };

  doRequest(options, { success, error, afterall, anonymous });
};

export const Request = (method, url, data, success, error, afterall, cancelToken, timeoutOverride, anonymous) => {
  const options = {
    headers: !anonymous && { Authorization: getToken() },
    method,
    url,
    data,
    cancelToken,
    timeout: timeoutOverride || consts.timeout
  };

  doRequest(options, { success, error, afterall, anonymous });
};

const doRequestAsync = async (options) => {
  const _options = { ...options, url: addSiteIdQuery(options.url) };
  try {
    const response = await axios.request(_options);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response && error.response.status === 401) {
      refreshAuthorisationAsync()
        .then((ok) => {
          if (ok) {
            doRequestAsync({ ...options, headers: { Authorization: getToken() } });
          } else {
            sessionStorage.clear();
            window.location.replace('/login');
          }
        });
      return null;
    }

    const errorObj = onError(error);
    throw new Error(errorObj);
  }
};

export const GetRequestAsync = async (url, cancelToken, timeoutOverride, anonymous) => {
  const options = {
    headers: !anonymous && { Authorization: getToken() },
    method: 'GET',
    url,
    timeout: timeoutOverride || consts.timeout,
    cancelToken,
  };

  const responseData = await doRequestAsync(options);
  return responseData;
};

export const PostRequestAsync = async (url, data, cancelToken, timeoutOverride, anonymous) => {
  const options = {
    headers: !anonymous && { Authorization: getToken() },
    method: 'POST',
    url,
    data,
    cancelToken,
    timeout: timeoutOverride || consts.timeout
  };

  const responseData = await doRequestAsync(options);
  return responseData;
};

export const RequestAsync = async (method, url, data, cancelToken, timeoutOverride, anonymous) => {
  const options = {
    headers: !anonymous && { Authorization: getToken() },
    method,
    url,
    data,
    cancelToken,
    timeout: timeoutOverride || consts.timeout
  };

  const responseData = await doRequestAsync(options);
  return responseData;
};
