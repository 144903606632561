const hide = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 4.71,3.29
 C 3.75,2.32 2.43,3.85 3.19,4.62
   3.19,4.62 8.92,10.33 8.92,10.33
   8.65,10.83 8.50,11.39 8.50,11.99
   8.50,13.92 10.07,15.49 12.00,15.49
   12.60,15.49 13.17,15.34 13.66,15.08
   13.66,15.08 19.29,20.71 19.29,20.71
   20.10,21.56 21.69,20.31 20.67,19.24
   20.67,19.24 4.71,3.29 4.71,3.29 Z
 M 10.97,13.12
 C 10.72,12.80 10.52,12.56 10.50,12.00
   10.50,12.00 12.06,13.49 12.06,13.49
   11.57,13.51 11.33,13.38 10.97,13.12 Z
 M 4.17,12.00
 C 4.80,11.00 5.66,10.09 6.52,9.28
   6.52,9.28 5.00,7.87 5.00,7.87
   3.88,8.93 2.84,10.33 2.06,11.67
   2.06,11.67 1.92,12.12 1.92,12.12
   3.89,16.15 7.95,18.84 12.23,19.05
   13.37,19.02 14.51,18.73 15.52,18.33
   15.52,18.33 13.92,16.75 13.92,16.75
   13.55,16.85 13.03,17.02 12.09,17.09
   8.88,17.02 5.77,14.65 4.17,12.00 Z
 M 21.87,11.50
 C 21.23,10.39 17.70,4.82 11.74,5.00
   10.60,5.03 9.51,5.26 8.50,5.67
   8.50,5.67 10.08,7.25 10.08,7.25
   10.62,7.11 11.19,7.02 11.78,7.00
   16.07,6.89 18.89,10.59 19.78,12.00
   19.14,13.01 18.37,13.92 17.49,14.72
   17.49,14.72 19.00,16.13 19.00,16.13
   20.32,14.50 21.46,13.96 22.05,11.88
   22.05,11.88 21.87,11.50 21.87,11.50 Z" />
  </g>
  </g>
`,
];

export default hide;
