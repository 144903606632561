const active = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:#0eb514;">
 <path d="M 12.00,22.00
 C 6.48,22.00 2.00,17.52 2.00,12.00
   2.00,6.48 6.48,2.00 12.00,2.00
   17.52,2.00 22.00,6.48 22.00,12.00
   22.00,17.52 17.52,22.00 12.00,22.00 Z
 M 11.00,16.00
 C 11.00,16.00 18.07,8.93 18.07,8.93
   18.07,8.93 16.66,7.51 16.66,7.51
   16.66,7.51 11.00,13.17 11.00,13.17
   11.00,13.17 8.17,10.34 8.17,10.34
   8.17,10.34 6.76,11.76 6.76,11.76
   6.76,11.76 11.00,16.00 11.00,16.00 Z" />
  </g>
  </g>
`,
];

export default active;
