const alarms = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 20.00,17.00
 C 20.00,17.00 22.00,17.00 22.00,17.00
   22.00,17.00 22.00,19.00 22.00,19.00
   22.00,19.00 2.00,19.00 2.00,19.00
   2.00,19.00 2.00,17.00 2.00,17.00
   2.00,17.00 4.00,17.00 4.00,17.00
   4.00,17.00 4.00,10.00 4.00,10.00
   4.00,5.58 7.58,2.00 12.00,2.00
   16.42,2.00 20.00,5.58 20.00,10.00
   20.00,10.00 20.00,17.00 20.00,17.00 Z
 M 18.00,17.00
 C 18.00,17.00 18.00,10.00 18.00,10.00
   18.00,6.69 15.31,4.00 12.00,4.00
   8.69,4.00 6.00,6.69 6.00,10.00
   6.00,10.00 6.00,17.00 6.00,17.00
   6.00,17.00 18.00,17.00 18.00,17.00 Z
 M 9.00,21.00
 C 9.00,21.00 15.00,21.00 15.00,21.00
   15.00,21.00 15.00,23.00 15.00,23.00
   15.00,23.00 9.00,23.00 9.00,23.00
   9.00,23.00 9.00,21.00 9.00,21.00 Z" />
  </g>
  </g>
`,
];

export default alarms;
