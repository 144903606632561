const avatar = [
  '128 128',
  `
    <title>Avatar</title>
    <g>
      <path
      fill="currentColor"
      d="M 128.00,64.00
      C 128.00,99.35 99.35,128.00 64.00,128.00
          28.65,128.00 0.00,99.35 0.00,64.00
          0.00,28.65 28.65,0.00 64.00,0.00
          96.50,0.00 127.75,24.25 128.00,64.00 Z" />
      <path 
      fill="#FFFFFF"
      d="M 103.00,102.14
          C 93.09,111.92 79.35,118.00 64.16,118.00
          48.81,118.00 34.93,111.77 25.00,101.79
          25.00,101.79 25.00,95.20 25.00,95.20
          25.00,86.81 31.98,80.00 40.60,80.00
          40.60,80.00 87.40,80.00 87.40,80.00
          96.02,80.00 103.00,86.81 103.00,95.20
          103.00,95.20 103.00,102.14 103.00,102.14 Z
          M 64.00,24.00
          C 51.29,24.00 41.00,34.29 41.00,47.00
          41.00,59.71 51.29,70.00 64.00,70.00
          76.70,70.00 87.00,59.71 87.00,47.00
          87.00,34.29 76.70,24.00 64.00,24.00" />
  </svg>
    </g>
  `,
];

export default avatar;
