const decommissioned = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 11.00,6.00
 C 11.00,6.00 11.00,8.00 11.00,8.00
   7.69,8.00 5.00,10.69 5.00,14.00
   5.00,17.31 7.69,20.00 11.00,20.00
   14.24,20.00 16.88,17.43 17.00,14.22
   17.00,14.22 17.00,14.00 17.00,14.00
   17.00,14.00 19.00,14.00 19.00,14.00
   19.00,18.42 15.42,22.00 11.00,22.00
   6.58,22.00 3.00,18.42 3.00,14.00
   3.00,9.66 6.58,6.00 11.00,6.00 Z
 M 21.00,2.00
 C 21.00,2.00 21.00,4.00 21.00,4.00
   21.00,4.00 15.67,10.00 15.67,10.00
   15.67,10.00 21.00,10.00 21.00,10.00
   21.00,10.00 21.00,12.00 21.00,12.00
   21.00,12.00 13.00,12.00 13.00,12.00
   13.00,12.00 13.00,10.00 13.00,10.00
   13.00,10.00 18.33,4.00 18.33,4.00
   18.33,4.00 13.00,4.00 13.00,4.00
   13.00,4.00 13.00,2.00 13.00,2.00
   13.00,2.00 21.00,2.00 21.00,2.00 Z" />
  </g>
  </g>
`,
];

export default decommissioned;
