const warning = [
  '15.961 14',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
  <path class="a" d="M15.687,83.21l-.005-.009L9.735,73.273l0,0a2.049,2.049,0,0,0-2.814-.689,2.057,2.057,0,0,
  0-.689.689l0,0L.281,83.2l-.005.009a2.049,2.049,0,0,0,1.752,3.073H13.935a2.049,2.049,0,0,0,1.752-3.073Zm-1.763,1.784H2.039a.759.759,
  0,0,1-.649-1.135l5.943-9.921a.762.762,0,0,1,.255-.254.759.759,0,0,1,1.042.254l5.942,9.921a.759.759,0,0,1-.648,1.135Z" transform="translate(0 -72.283)"/>
  <g transform="translate(7.152 3.779)"><g transform="translate(0)">
  <path class="a" d="M541.618,388.185a.829.829,0,0,0-.829.829v3.611a.829.829,0,0,0,1.658,0v-3.611A.829.829,0,0,0,541.618,388.185Z" transform="translate(-540.789 -388.185)"/></g></g>
  <g transform="translate(7.152 9.615)"><path class="a" d="M542.2,802.456a.829.829,0,1,0,.243.586A.834.834,0,0,0,542.2,802.456Z" transform="translate(-540.785 -802.213)"/></g>
`,
];

export default warning;
