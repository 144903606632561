const database = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 11.00,19.00
 C 11.00,19.00 11.00,9.00 11.00,9.00
   11.00,9.00 4.00,9.00 4.00,9.00
   4.00,9.00 4.00,19.00 4.00,19.00
   4.00,19.00 11.00,19.00 11.00,19.00 Z
 M 11.00,7.00
 C 11.00,7.00 11.00,4.00 11.00,4.00
   11.00,3.45 11.45,3.00 12.00,3.00
   12.00,3.00 21.00,3.00 21.00,3.00
   21.55,3.00 22.00,3.45 22.00,4.00
   22.00,4.00 22.00,20.00 22.00,20.00
   22.00,20.55 21.55,21.00 21.00,21.00
   21.00,21.00 3.00,21.00 3.00,21.00
   2.45,21.00 2.00,20.55 2.00,20.00
   2.00,20.00 2.00,8.00 2.00,8.00
   2.00,7.45 2.45,7.00 3.00,7.00
   3.00,7.00 11.00,7.00 11.00,7.00 Z
 M 13.00,5.00
 C 13.00,5.00 13.00,19.00 13.00,19.00
   13.00,19.00 20.00,19.00 20.00,19.00
   20.00,19.00 20.00,5.00 20.00,5.00
   20.00,5.00 13.00,5.00 13.00,5.00 Z
 M 5.00,16.00
 C 5.00,16.00 10.00,16.00 10.00,16.00
   10.00,16.00 10.00,18.00 10.00,18.00
   10.00,18.00 5.00,18.00 5.00,18.00
   5.00,18.00 5.00,16.00 5.00,16.00 Z
 M 14.00,16.00
 C 14.00,16.00 19.00,16.00 19.00,16.00
   19.00,16.00 19.00,18.00 19.00,18.00
   19.00,18.00 14.00,18.00 14.00,18.00
   14.00,18.00 14.00,16.00 14.00,16.00 Z
 M 14.00,13.00
 C 14.00,13.00 19.00,13.00 19.00,13.00
   19.00,13.00 19.00,15.00 19.00,15.00
   19.00,15.00 14.00,15.00 14.00,15.00
   14.00,15.00 14.00,13.00 14.00,13.00 Z
 M 14.00,10.00
 C 14.00,10.00 19.00,10.00 19.00,10.00
   19.00,10.00 19.00,12.00 19.00,12.00
   19.00,12.00 14.00,12.00 14.00,12.00
   14.00,12.00 14.00,10.00 14.00,10.00 Z
 M 5.00,13.00
 C 5.00,13.00 10.00,13.00 10.00,13.00
   10.00,13.00 10.00,15.00 10.00,15.00
   10.00,15.00 5.00,15.00 5.00,15.00
   5.00,15.00 5.00,13.00 5.00,13.00 Z" />
  </g>
  </g>
`,
];

export default database;
