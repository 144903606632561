const lineChart = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 5.00,3.00
 C 5.00,3.00 5.00,19.00 5.00,19.00
   5.00,19.00 21.00,19.00 21.00,19.00
   21.00,19.00 21.00,21.00 21.00,21.00
   21.00,21.00 3.00,21.00 3.00,21.00
   3.00,21.00 3.00,3.00 3.00,3.00
   3.00,3.00 5.00,3.00 5.00,3.00 Z
 M 20.29,6.29
 C 20.29,6.29 21.71,7.71 21.71,7.71
   21.71,7.71 16.00,13.41 16.00,13.41
   16.00,13.41 13.00,10.41 13.00,10.41
   13.00,10.41 8.71,14.71 8.71,14.71
   8.71,14.71 7.29,13.29 7.29,13.29
   7.29,13.29 13.00,7.59 13.00,7.59
   13.00,7.59 16.00,10.59 16.00,10.59
   16.00,10.59 20.29,6.29 20.29,6.29
   20.29,6.29 20.29,6.29 20.29,6.29 Z" />
  </g>
  </g>
`,
];

export default lineChart;
