import React from 'react';
import PropTypes from 'prop-types';
import '../scss/_spinner.scss';
import Loader from 'react-loader-spinner';

const LoadingSpinner = ({ type, color, width, height, absolute }) => {
  const positionCls = absolute ? 'spinner-absolute' : 'spinner-relative';

  return (
    <div className={`d-flex align-items-center justify-content-center flex-column ${positionCls}`}>
      <Loader type={type} color={color} height={height} width={width} />
    </div>
  );
};

LoadingSpinner.defaultProps = {
  absolute: false,
  type: 'Oval',
  color: 'rgba(108, 125, 139, 0.85)', // '#00BFFF',
  height: 40,
  width: 40
};

LoadingSpinner.propTypes = {
  absolute: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
  type: PropTypes.string,
};

export default LoadingSpinner;
