const map = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 12.00,22.00
 C 6.48,22.00 2.00,17.52 2.00,12.00
   2.00,6.48 6.48,2.00 12.00,2.00
   17.52,2.00 22.00,6.48 22.00,12.00
   22.00,17.52 17.52,22.00 12.00,22.00 Z
 M 9.71,19.67
 C 8.74,17.64 8.15,15.38 8.03,13.01
   8.03,13.01 4.06,13.00 4.06,13.00
   4.46,16.17 6.71,18.77 9.70,19.67
   9.70,19.67 9.71,19.67 9.71,19.67 Z
 M 10.03,13.00
 C 10.18,16.29 11.45,18.83 12.02,19.81
   12.47,18.78 13.80,16.53 13.98,13.00
   13.98,13.00 10.03,13.00 10.03,13.00
   10.03,13.00 10.03,13.00 10.03,13.00 Z
 M 19.94,13.00
 C 19.94,13.00 15.97,13.00 15.97,13.00
   15.85,15.37 15.26,17.63 14.29,19.67
   17.28,18.77 19.54,16.18 19.94,13.01
   19.94,13.01 19.94,13.00 19.94,13.00 Z
 M 4.06,11.00
 C 4.06,11.00 8.03,11.00 8.03,11.00
   8.15,8.63 8.74,6.37 9.71,4.33
   6.72,5.23 4.46,7.82 4.06,10.99
   4.06,10.99 4.06,11.00 4.06,11.00 Z
 M 10.03,11.00
 C 10.03,11.00 13.97,11.00 13.97,11.00
   13.82,8.56 13.13,6.27 12.00,4.25
   10.88,6.26 10.18,8.55 10.03,10.99
   10.03,10.99 10.03,11.00 10.03,11.00 Z
 M 14.29,4.33
 C 15.25,6.36 15.85,8.62 15.97,10.99
   15.97,10.99 19.94,11.00 19.94,11.00
   19.54,7.83 17.29,5.23 14.30,4.33
   14.30,4.33 14.29,4.33 14.29,4.33 Z" />
  </g>
  </g>
`,
];

export default map;
