/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import PrivateRoute from './PrivateRoute';
import LoadingSpinner from './components/LoadingSpinner';

const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./views/pages/Login'));
const ForgotPassword = React.lazy(() => import('./views/pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/pages/ResetPassword'));
const Page404 = React.lazy(() => import('./views/pages/Page404'));
const Page500 = React.lazy(() => import('./views/pages/Page500'));

const App = () => (
  <BrowserRouter>
    <Suspense fallback={<LoadingSpinner absolute />}>
      <Switch>
        <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
        <Route exact path="/forgotpassword" name="Forgot Password" render={(props) => <ForgotPassword {...props} />} />
        <Route exact path="/completeregistration" name="Complete registration" render={(props) => <ResetPassword completeRegistration {...props} />} />
        <Route exact path="/resetpassword" name="Reset Password" render={(props) => <ResetPassword {...props} />} />
        <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
        <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
        <PrivateRoute path="/" name="Home" component={TheLayout} />
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default App;
