const siteManagement = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 6.00,19.00
 C 6.00,19.00 18.00,19.00 18.00,19.00
   18.00,19.00 18.00,9.16 18.00,9.16
   18.00,9.16 12.00,3.70 12.00,3.70
   12.00,3.70 6.00,9.16 6.00,9.16
   6.00,9.16 6.00,19.00 6.00,19.00 Z
 M 19.00,21.00
 C 19.00,21.00 5.00,21.00 5.00,21.00
   4.45,21.00 4.00,20.55 4.00,20.00
   4.00,20.00 4.00,11.00 4.00,11.00
   4.00,11.00 1.00,11.00 1.00,11.00
   1.00,11.00 11.33,1.61 11.33,1.61
   11.50,1.45 11.74,1.35 12.00,1.35
   12.26,1.35 12.50,1.45 12.67,1.61
   12.67,1.61 23.00,11.00 23.00,11.00
   23.00,11.00 20.00,11.00 20.00,11.00
   20.00,11.00 20.00,20.00 20.00,20.00
   20.00,20.55 19.55,21.00 19.00,21.00
   19.00,21.00 19.00,21.00 19.00,21.00 Z
 M 8.00,10.00
 C 11.87,10.00 15.00,13.13 15.00,17.00
   15.00,17.00 13.00,17.00 13.00,17.00
   13.00,14.24 10.76,12.00 8.00,12.00
   8.00,12.00 8.00,10.00 8.00,10.00 Z
 M 8.00,14.00
 C 9.66,14.00 11.00,15.34 11.00,17.00
   11.00,17.00 8.00,17.00 8.00,17.00
   8.00,17.00 8.00,14.00 8.00,14.00 Z" />
  </g>
  </g>
`,
];

export default siteManagement;
