import i18next from 'i18next';

export const consts = {
  autorefresh: 20000,
  timeout: 20000,
  userdata: 'userdata',
  currentSite: 'currentSite',
  authentication: 'authentication',
  appVersion: 'appVersion'
};

export const tableSettings = {
  filter: { label: ' ', placeholder: i18next.t('table.Search', { ns: 'translation' }) },
  filterFn: (t) => ({ label: t('table.Search', { ns: 'translation' }), values: [10, 20, 50, 100] }),
  pagination: {
    doubleArrows: false,
    align: 'end',
    className: 'users-pagination',
    dots: false,
  },
  itemsPerPage: 50,
  items: { label: i18next.t('table.ShowRecords', { ns: 'translation' }), values: [10, 20, 50, 100] },
  itemsFn: (t) => ({ label: t('table.ShowRecords', { ns: 'translation' }), values: [10, 20, 50, 100] })
};

export const urls = {
  appSettings: '/api/app/appSettings',
  // Auth
  signin: '/api/auth/signin',
  signinrefresh: '/api/auth/refreshsignin',
  signout: '/api/auth/signout',
  forgotpassword: 'api/auth/passwordresetrequest',
  resetpassword: 'api/auth/passwordreset',
  completeregistration: '/api/auth/completeregistration',
  // User Management
  users: '/api/users/',
  profile: '/api/users/profile',
  // Clients Management
  resendactivationlink: '/api/Tenants/resendactivationlink/',
  tenants: '/api/Tenants/',
  tenantsinfo: '/api/Tenants/info',
  settings: '/api/Tenants/settings',
};
