const cog = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="var(--ci-primary-color, currentColor)">
 <path d="M 22.85,14.80
 C 22.85,14.80 20.85,13.65 20.85,13.65
   21.05,12.56 21.05,11.44 20.85,10.35
   20.85,10.35 22.85,9.20 22.85,9.20
   23.08,9.07 23.18,8.80 23.10,8.55
   22.58,6.88 21.70,5.37 20.54,4.11
   20.36,3.92 20.07,3.87 19.85,4.00
   19.85,4.00 17.85,5.16 17.85,5.16
   17.01,4.43 16.05,3.88 15.00,3.51
   15.00,3.51 15.00,1.21 15.00,1.21
   15.00,0.95 14.82,0.72 14.56,0.66
   12.84,0.28 11.08,0.30 9.44,0.66
   9.18,0.72 9.00,0.95 9.00,1.21
   9.00,1.21 9.00,3.52 9.00,3.52
   7.96,3.89 6.99,4.44 6.15,5.16
   6.15,5.16 4.16,4.01 4.16,4.01
   3.93,3.88 3.64,3.92 3.46,4.12
   2.31,5.37 1.42,6.88 0.90,8.55
   0.82,8.83 1.43,8.81 1.56,8.81
   1.56,8.81 3.15,10.36 3.15,10.36
   2.95,11.45 2.95,12.56 3.15,13.65
   3.15,13.65 1.16,14.80 1.16,14.80
   0.93,14.93 0.82,15.21 0.90,15.46
   1.42,17.13 2.31,18.64 3.46,19.89
   3.64,20.09 3.93,20.13 4.16,20.00
   4.16,20.00 6.15,18.85 6.15,18.85
   6.99,19.57 7.96,20.13 9.00,20.49
   9.00,20.49 9.00,22.80 9.00,22.80
   9.00,23.06 9.19,23.29 9.45,23.35
   11.17,23.73 12.93,23.71 14.56,23.35
   14.82,23.29 15.00,23.06 15.00,22.80
   15.00,22.80 15.00,20.49 15.00,20.49
   16.05,20.12 17.01,19.57 17.85,18.85
   17.85,18.85 19.85,20.00 19.85,20.00
   20.08,20.13 20.37,20.09 20.55,19.89
   21.70,18.64 22.59,17.13 23.11,15.46
   23.18,15.20 23.08,14.93 22.85,14.80
   22.85,14.80 22.85,14.80 22.85,14.80 Z
 M 12.00,15.75
 C 9.93,15.75 8.25,14.07 8.25,12.00
   8.25,9.93 9.93,8.25 12.00,8.25
   14.07,8.25 15.75,9.93 15.75,12.00
   15.75,14.07 14.07,15.75 12.00,15.75 Z" class="ci-primary" />
    </g>
    </g>
  `,
];

export default cog;
