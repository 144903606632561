/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'message',
  initialState: {
    messages: [],
    confirmResult: {
      ok: false,
      id: -1
    }
  },
  reducers: {
    addMessage: (state, { payload }) => {
      state.messages = [...state.messages, { ...payload, id: Date.now() }];
    },
    removeMessage: (state, { payload }) => {
      state.messages = state.messages.filter((s) => s.id !== payload);
    },
    info: (state, { payload }) => {
      state.messages = [{ text: payload, type: 'info', id: Date.now() }];
    },
    success: (state, { payload }) => {
      state.messages = [{ text: payload, type: 'success', id: Date.now() }];
    },
    warning: (state, { payload }) => {
      state.messages = [{ text: payload, type: 'warning', id: Date.now() }];
    },
    alert: (state, { payload }) => {
      state.messages = [{ text: payload, type: 'danger', id: Date.now() }];
    },
    confirm: (state, { payload }) => {
      const { actionId, data, text, timeout, type, confirm, acknowledge } = payload;
      state.messages = [{ actionId, data, text, type: type || 'warning', id: Date.now(), timeout: timeout || 10, confirm, acknowledge }];
    },
    onConfirm: (state, { payload }) => {
      state.confirmResult = payload;
    },
    clearMessages: (state) => {
      state.messages = [];
    },
  }
});

export const { addMessage, removeMessage, info, success, warning, alert, clearMessages, confirm, onConfirm } = slice.actions;

export const confirmResult = (state) => state.message.confirmResult;

export default slice.reducer;
