const barChart = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 3.00,3.00
 C 3.00,3.00 21.00,3.00 21.00,3.00
   21.55,3.00 22.00,3.45 22.00,4.00
   22.00,4.00 22.00,20.00 22.00,20.00
   22.00,20.55 21.55,21.00 21.00,21.00
   21.00,21.00 3.00,21.00 3.00,21.00
   2.45,21.00 2.00,20.55 2.00,20.00
   2.00,20.00 2.00,4.00 2.00,4.00
   2.00,3.45 2.45,3.00 3.00,3.00
   3.00,3.00 3.00,3.00 3.00,3.00 Z
 M 4.00,5.00
 C 4.00,5.00 4.00,19.00 4.00,19.00
   4.00,19.00 20.00,19.00 20.00,19.00
   20.00,19.00 20.00,5.00 20.00,5.00
   20.00,5.00 4.00,5.00 4.00,5.00 Z
 M 7.00,13.00
 C 7.00,13.00 9.00,13.00 9.00,13.00
   9.00,13.00 9.00,17.00 9.00,17.00
   9.00,17.00 7.00,17.00 7.00,17.00
   7.00,17.00 7.00,13.00 7.00,13.00 Z
 M 11.00,7.00
 C 11.00,7.00 13.00,7.00 13.00,7.00
   13.00,7.00 13.00,17.00 13.00,17.00
   13.00,17.00 11.00,17.00 11.00,17.00
   11.00,17.00 11.00,7.00 11.00,7.00 Z
 M 15.00,10.00
 C 15.00,10.00 17.00,10.00 17.00,10.00
   17.00,10.00 17.00,17.00 17.00,17.00
   17.00,17.00 15.00,17.00 15.00,17.00
   15.00,17.00 15.00,10.00 15.00,10.00 Z" />
  </g>
  </g>
`,
];

export default barChart;
