const supportRequest = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 22.00,17.00
 C 22.00,19.87 19.98,22.27 17.29,22.86
   17.29,22.86 16.65,20.95 16.65,20.95
   17.85,20.75 18.88,20.02 19.46,19.00
   19.46,19.00 17.00,19.00 17.00,19.00
   15.90,19.00 15.00,18.10 15.00,17.00
   15.00,17.00 15.00,13.00 15.00,13.00
   15.00,11.90 15.90,11.00 17.00,11.00
   17.00,11.00 19.94,11.00 19.94,11.00
   19.45,7.05 16.08,4.00 12.00,4.00
   7.92,4.00 4.56,7.05 4.06,10.99
   4.06,10.99 7.00,11.00 7.00,11.00
   8.10,11.00 9.00,11.90 9.00,13.00
   9.00,13.00 9.00,17.00 9.00,17.00
   9.00,18.10 8.10,19.00 7.00,19.00
   7.00,19.00 4.00,19.00 4.00,19.00
   2.90,19.00 2.00,18.10 2.00,17.00
   2.00,17.00 2.00,12.00 2.00,12.00
   2.00,6.48 6.48,2.00 12.00,2.00
   17.52,2.00 22.00,6.48 22.00,12.00
   22.00,12.00 22.00,17.00 22.00,17.00 Z
 M 20.00,17.00
 C 20.00,17.00 20.00,13.00 20.00,13.00
   20.00,13.00 17.00,13.00 17.00,13.00
   17.00,13.00 17.00,17.00 17.00,17.00
   17.00,17.00 20.00,17.00 20.00,17.00 Z
 M 4.00,13.00
 C 4.00,13.00 4.00,17.00 4.00,17.00
   4.00,17.00 7.00,17.00 7.00,17.00
   7.00,17.00 7.00,13.00 7.00,13.00
   7.00,13.00 4.00,13.00 4.00,13.00 Z" />
  </g>
  </g>
`,
];

export default supportRequest;
