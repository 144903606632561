const download = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 13.00,13.00
 C 13.00,13.00 13.00,18.59 13.00,18.59
   13.00,18.59 14.83,16.76 14.83,16.76
   14.83,16.76 16.24,18.17 16.24,18.17
   16.24,18.17 12.00,22.41 12.00,22.41
   12.00,22.41 7.76,18.17 7.76,18.17
   7.76,18.17 9.17,16.76 9.17,16.76
   9.17,16.76 11.00,18.58 11.00,18.58
   11.00,18.58 11.00,13.00 11.00,13.00
   11.00,13.00 13.00,13.00 13.00,13.00 Z
 M 12.00,2.00
 C 15.59,2.00 18.55,4.70 18.95,8.19
   21.30,8.82 23.02,10.96 23.02,13.50
   23.02,16.37 20.82,18.73 18.00,18.98
   18.00,18.98 18.00,16.96 18.00,16.96
   19.70,16.72 21.01,15.26 21.01,13.50
   21.01,11.57 19.44,10.00 17.51,10.00
   17.30,10.00 17.09,10.02 16.89,10.05
   16.96,9.72 17.00,9.37 17.00,9.00
   17.00,6.24 14.76,4.00 12.00,4.00
   9.24,4.00 7.00,6.24 7.00,9.00
   7.00,9.36 7.04,9.72 7.11,10.05
   6.90,10.01 6.69,9.99 6.47,9.99
   4.53,9.99 2.97,11.56 2.97,13.49
   2.97,15.21 4.19,16.63 5.82,16.93
   5.82,16.93 6.03,16.93 6.03,16.93
   6.03,16.93 6.03,18.95 6.03,18.95
   3.22,18.70 1.02,16.34 1.02,13.47
   1.02,10.94 2.74,8.80 5.07,8.16
   5.48,4.70 8.43,2.01 12.00,2.00
   12.00,2.00 12.00,2.00 12.00,2.00 Z" />
  </g>
  </g>
`,
];

export default download;
