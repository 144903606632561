const faq = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 12.00,22.00
 C 6.48,22.00 2.00,17.52 2.00,12.00
   2.00,6.48 6.48,2.00 12.00,2.00
   17.52,2.00 22.00,6.48 22.00,12.00
   22.00,17.52 17.52,22.00 12.00,22.00 Z
 M 12.00,20.00
 C 16.42,20.00 20.00,16.42 20.00,12.00
   20.00,7.58 16.42,4.00 12.00,4.00
   7.58,4.00 4.00,7.58 4.00,12.00
   4.00,16.42 7.58,20.00 12.00,20.00
   12.00,20.00 12.00,20.00 12.00,20.00 Z
 M 11.00,15.00
 C 11.00,15.00 13.00,15.00 13.00,15.00
   13.00,15.00 13.00,17.00 13.00,17.00
   13.00,17.00 11.00,17.00 11.00,17.00
   11.00,17.00 11.00,15.00 11.00,15.00 Z
 M 13.00,13.36
 C 13.00,13.36 13.00,14.00 13.00,14.00
   13.00,14.00 11.00,14.00 11.00,14.00
   11.00,14.00 11.00,12.50 11.00,12.50
   11.00,11.95 11.45,11.50 12.00,11.50
   12.83,11.50 13.50,10.83 13.50,10.00
   13.50,9.17 12.83,8.50 12.00,8.50
   11.27,8.50 10.67,9.02 10.53,9.70
   10.53,9.70 8.57,9.31 8.57,9.31
   8.89,7.71 10.30,6.50 12.00,6.50
   13.93,6.50 15.50,8.07 15.50,10.00
   15.50,11.58 14.45,12.92 13.00,13.36
   13.00,13.36 13.00,13.36 13.00,13.36 Z" />
  </g>
  </g>
`,
];

export default faq;
