const dots = [
  '300 300',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
  <path d="M 255.56,150.00
  C 255.56,166.33 242.33,179.56 226.00,179.56
    209.67,179.56 196.44,166.33 196.44,150.00
    196.44,133.67 209.67,120.44 226.00,120.44
    226.00,120.44 242.33,120.44 255.56,150.00 Z
  M 179.56,150.00
  C 179.56,166.33 166.33,179.56 150.00,179.56
    133.67,179.56 120.44,166.33 120.44,150.00
    120.44,133.67 133.67,120.44 150.00,120.44
    150.00,120.44 166.33,120.44 179.56,150.00 Z
  M 104.12,150.00
  C 104.12,166.33 90.89,179.56 74.56,179.56
    58.24,179.56 45.00,166.33 45.00,150.00
    45.00,133.67 58.24,120.44 74.56,120.44
    74.56,120.44 90.89,120.44 104.12,150.00 Z" />
    </g>
  </g>
`,
];

export default dots;
