const calendar = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 17.00,3.00
 C 17.00,3.00 21.00,3.00 21.00,3.00
   21.55,3.00 22.00,3.45 22.00,4.00
   22.00,4.00 22.00,20.00 22.00,20.00
   22.00,20.55 21.55,21.00 21.00,21.00
   21.00,21.00 3.00,21.00 3.00,21.00
   2.45,21.00 2.00,20.55 2.00,20.00
   2.00,20.00 2.00,4.00 2.00,4.00
   2.00,3.45 2.45,3.00 3.00,3.00
   3.00,3.00 7.00,3.00 7.00,3.00
   7.00,3.00 7.00,1.00 7.00,1.00
   7.00,1.00 9.00,1.00 9.00,1.00
   9.00,1.00 9.00,3.00 9.00,3.00
   9.00,3.00 15.00,3.00 15.00,3.00
   15.00,3.00 15.00,1.00 15.00,1.00
   15.00,1.00 17.00,1.00 17.00,1.00
   17.00,1.00 17.00,3.00 17.00,3.00 Z
 M 20.00,11.00
 C 20.00,11.00 4.00,11.00 4.00,11.00
   4.00,11.00 4.00,19.00 4.00,19.00
   4.00,19.00 20.00,19.00 20.00,19.00
   20.00,19.00 20.00,11.00 20.00,11.00 Z
 M 15.00,5.00
 C 15.00,5.00 9.00,5.00 9.00,5.00
   9.00,5.00 9.00,7.00 9.00,7.00
   9.00,7.00 7.00,7.00 7.00,7.00
   7.00,7.00 7.00,5.00 7.00,5.00
   7.00,5.00 4.00,5.00 4.00,5.00
   4.00,5.00 4.00,9.00 4.00,9.00
   4.00,9.00 20.00,9.00 20.00,9.00
   20.00,9.00 20.00,5.00 20.00,5.00
   20.00,5.00 17.00,5.00 17.00,5.00
   17.00,5.00 17.00,7.00 17.00,7.00
   17.00,7.00 15.00,7.00 15.00,7.00
   15.00,7.00 15.00,5.00 15.00,5.00 Z
 M 6.00,13.00
 C 6.00,13.00 8.00,13.00 8.00,13.00
   8.00,13.00 8.00,15.00 8.00,15.00
   8.00,15.00 6.00,15.00 6.00,15.00
   6.00,15.00 6.00,13.00 6.00,13.00 Z
 M 11.00,13.00
 C 11.00,13.00 13.00,13.00 13.00,13.00
   13.00,13.00 13.00,15.00 13.00,15.00
   13.00,15.00 11.00,15.00 11.00,15.00
   11.00,15.00 11.00,13.00 11.00,13.00 Z
 M 16.00,13.00
 C 16.00,13.00 18.00,13.00 18.00,13.00
   18.00,13.00 18.00,15.00 18.00,15.00
   18.00,15.00 16.00,15.00 16.00,15.00
   16.00,15.00 16.00,13.00 16.00,13.00 Z" />
  </g>
  </g>
`,
];

export default calendar;
