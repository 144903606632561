const arrowDown = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
  <path d="M 12.00,15.00
  C 12.00,15.00 7.76,10.76 7.76,10.76
    7.76,10.76 9.17,9.34 9.17,9.34
    9.17,9.34 12.00,12.17 12.00,12.17
    12.00,12.17 14.83,9.34 14.83,9.34
    14.83,9.34 16.24,10.76 16.24,10.76
    16.24,10.76 12.00,15.00 12.00,15.00 Z" />
    </g>
  </g>
`,
];

export default arrowDown;
