const instrument = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 12.00,3.00
 C 16.28,3.00 20.22,4.50 23.31,7.00
   23.31,7.00 12.00,21.00 12.00,21.00
   12.00,21.00 0.69,7.00 0.69,7.00
   3.78,4.50 7.71,3.00 12.00,3.00 Z
 M 12.00,5.00
 C 8.97,5.00 7.22,5.53 3.52,7.31
   3.52,7.31 5.53,9.70 5.53,9.70
   7.36,8.72 9.62,8.00 12.00,8.00
   14.37,8.00 16.97,8.95 18.61,9.80
   18.61,9.80 20.42,7.39 20.42,7.39
   17.92,5.84 15.03,5.00 12.00,5.00 Z" />
  </g>
  </g>
`,
];

export default instrument;
