const arrowUp = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
  <path d="M 12.00,11.83
  C 12.00,11.83 9.17,14.66 9.17,14.66
    9.17,14.66 7.76,13.24 7.76,13.24
    7.76,13.24 12.00,9.00 12.00,9.00
    12.00,9.00 16.24,13.24 16.24,13.24
    16.24,13.24 14.83,14.66 14.83,14.66
    14.83,14.66 12.00,11.83 12.00,11.83 Z" />
    </g>
  </g>
`,
];

export default arrowUp;
