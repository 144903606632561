const inactive = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:#e30909;">
 <path d="M 12.00,22.00
 C 6.48,22.00 2.00,17.52 2.00,12.00
   2.00,6.48 6.48,2.00 12.00,2.00
   17.52,2.00 22.00,6.48 22.00,12.00
   22.00,17.52 17.52,22.00 12.00,22.00 Z
 M 12.00,10.59
 C 12.00,10.59 9.17,7.76 9.17,7.76
   9.17,7.76 7.76,9.17 7.76,9.17
   7.76,9.17 10.59,12.00 10.59,12.00
   10.59,12.00 7.76,14.83 7.76,14.83
   7.76,14.83 9.17,16.24 9.17,16.24
   9.17,16.24 12.00,13.41 12.00,13.41
   12.00,13.41 14.83,16.24 14.83,16.24
   14.83,16.24 16.24,14.83 16.24,14.83
   16.24,14.83 13.41,12.00 13.41,12.00
   13.41,12.00 16.24,9.17 16.24,9.17
   16.24,9.17 14.83,7.76 14.83,7.76
   14.83,7.76 12.00,10.59 12.00,10.59 Z" />
  </g>
  </g>
`,
];

export default inactive;
