const alarmsHistory = [
  '20 20',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 13.82,15.00
 C 13.82,15.00 5.34,6.52 5.34,6.52
   5.12,7.08 5.00,7.69 5.00,8.33
   5.00,8.33 5.00,8.33 5.00,8.33
   5.00,8.33 5.00,15.00 5.00,15.00
   5.00,15.00 13.82,15.00 13.82,15.00 Z
 M 15.55,4.63
 C 16.25,5.69 16.67,6.96 16.67,8.33
   16.67,8.33 16.67,13.16 16.67,13.16
   16.67,13.16 15.00,11.49 15.00,11.49
   15.00,11.49 15.00,8.33 15.00,8.33
   15.00,8.33 15.00,8.33 15.00,8.33
   15.00,5.57 12.76,3.33 10.00,3.33
   9.09,3.33 8.24,3.57 7.51,4.00
   7.51,4.00 6.30,2.79 6.30,2.79
   7.36,2.08 8.63,1.67 10.00,1.67
   12.31,1.67 14.35,2.84 15.55,4.63
   15.55,4.63 15.55,4.63 15.55,4.63 Z
 M 11.91,17.55
 C 11.91,17.55 11.91,19.21 11.91,19.21
   11.91,19.21 6.91,19.21 6.91,19.21
   6.91,19.21 6.91,17.55 6.91,17.55
   6.91,17.55 11.91,17.55 11.91,17.55 Z
 M 17.66,18.84
 C 17.66,18.84 15.49,16.67 15.49,16.67
   15.49,16.67 1.91,16.67 1.91,16.67
   1.91,16.67 1.91,15.00 1.91,15.00
   1.91,15.00 3.33,15.00 3.33,15.00
   3.33,15.00 3.33,8.33 3.33,8.33
   3.33,8.33 3.33,8.33 3.33,8.32
   3.33,7.22 3.60,6.18 4.08,5.26
   4.08,5.26 1.16,2.34 1.16,2.34
   1.16,2.34 2.34,1.16 2.34,1.16
   2.34,1.16 18.84,17.66 18.84,17.66
   18.84,17.66 17.66,18.84 17.66,18.84 Z
 M 11.91,17.55
 C 11.91,17.55 11.91,19.21 11.91,19.21
   11.91,19.21 6.91,19.21 6.91,19.21
   6.91,19.21 6.91,17.55 6.91,17.55
   6.91,17.55 11.91,17.55 11.91,17.55 Z" />
  </g>
  </g>
`,
];

export default alarmsHistory;
