const filter = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 18.00,21.00
 C 18.00,21.00 21.00,21.00 21.00,21.00
   21.00,21.00 21.00,19.00 21.00,19.00
   21.00,19.00 18.00,19.00 18.00,19.00
   18.00,19.00 18.00,17.00 18.00,17.00
   18.00,17.00 16.00,17.00 16.00,17.00
   16.00,17.00 16.00,23.00 16.00,23.00
   16.00,23.00 18.00,23.00 18.00,23.00
   18.00,23.00 18.00,21.00 18.00,21.00 Z
 M 18.00,5.00
 C 18.00,5.00 21.00,5.00 21.00,5.00
   21.00,5.00 21.00,3.00 21.00,3.00
   21.00,3.00 18.00,3.00 18.00,3.00
   18.00,3.00 18.00,1.00 18.00,1.00
   18.00,1.00 16.00,1.00 16.00,1.00
   16.00,1.00 16.00,7.00 16.00,7.00
   16.00,7.00 18.00,7.00 18.00,7.00
   18.00,7.00 18.00,5.00 18.00,5.00 Z
 M 6.00,11.00
 C 6.00,11.00 3.00,11.00 3.00,11.00
   3.00,11.00 3.00,13.00 3.00,13.00
   3.00,13.00 6.00,13.00 6.00,13.00
   6.00,13.00 6.00,15.00 6.00,15.00
   6.00,15.00 8.00,15.00 8.00,15.00
   8.00,15.00 8.00,9.00 8.00,9.00
   8.00,9.00 6.00,9.00 6.00,9.00
   6.00,9.00 6.00,11.00 6.00,11.00 Z
 M 10.00,11.00
 C 10.00,11.00 10.00,13.00 10.00,13.00
   10.00,13.00 21.00,13.00 21.00,13.00
   21.00,13.00 21.00,11.00 21.00,11.00
   21.00,11.00 10.00,11.00 10.00,11.00 Z
 M 14.00,3.00
 C 14.00,3.00 3.00,3.00 3.00,3.00
   3.00,3.00 3.00,5.00 3.00,5.00
   3.00,5.00 14.00,5.00 14.00,5.00
   14.00,5.00 14.00,3.00 14.00,3.00 Z
 M 14.00,19.00
 C 14.00,19.00 3.00,19.00 3.00,19.00
   3.00,19.00 3.00,21.00 3.00,21.00
   3.00,21.00 14.00,21.00 14.00,21.00
   14.00,21.00 14.00,19.00 14.00,19.00 Z" />
  </g>
  </g>
`,
];

export default filter;
