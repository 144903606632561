const note = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 21.00,6.76
 C 21.00,6.76 19.00,8.76 19.00,8.76
   19.00,8.76 19.00,4.00 19.00,4.00
   19.00,4.00 10.00,4.00 10.00,4.00
   10.00,4.00 10.00,9.00 10.00,9.00
   10.00,9.00 5.00,9.00 5.00,9.00
   5.00,9.00 5.00,20.00 5.00,20.00
   5.00,20.00 19.00,20.00 19.00,20.00
   19.00,20.00 19.00,17.24 19.00,17.24
   19.00,17.24 21.00,15.24 21.00,15.24
   21.00,15.24 21.00,21.01 21.00,21.01
   21.00,21.01 20.91,21.96 20.02,22.01
   20.02,22.01 5.16,21.98 4.12,21.98
   2.88,22.01 3.16,21.00 3.00,20.99
   3.00,20.99 3.00,8.00 3.00,8.00
   3.00,8.00 9.00,2.00 9.00,2.00
   9.00,2.00 20.00,2.00 20.00,2.00
   20.55,2.00 21.00,2.45 21.00,2.99
   21.00,2.99 21.00,6.76 21.00,6.76 Z
 M 21.78,8.81
 C 21.78,8.81 23.19,10.22 23.19,10.22
   23.19,10.22 15.41,18.00 15.41,18.00
   15.41,18.00 14.00,18.00 14.00,18.00
   14.00,18.00 14.02,16.59 14.02,16.59
   14.02,16.59 21.78,8.81 21.78,8.81 Z" />
  </g>
  </g>
`,
];

export default note;
