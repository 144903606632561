const pressure = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 12.00,2.00
 C 17.52,2.00 22.00,6.48 22.00,12.00
   22.00,17.52 17.52,22.00 12.00,22.00
   6.48,22.00 2.00,17.52 2.00,12.00
   2.00,6.48 6.48,2.00 12.00,2.00 Z
 M 12.00,4.00
 C 7.58,4.00 4.00,7.58 4.00,12.00
   4.00,16.42 7.58,20.00 12.00,20.00
   16.42,20.00 20.00,16.42 20.00,12.00
   20.00,7.58 16.42,4.00 12.00,4.00 Z
 M 12.00,5.00
 C 13.43,4.92 14.77,5.60 14.77,5.60
   14.77,5.60 13.29,7.17 13.29,7.17
   12.88,7.06 12.45,7.00 12.00,7.00
   9.24,7.00 7.00,9.24 7.00,12.00
   7.00,13.94 7.99,14.86 8.44,15.52
   8.44,15.52 7.05,16.95 7.05,16.95
   7.05,16.95 6.89,16.79 6.89,16.79
   5.72,15.54 5.00,13.85 5.00,12.00
   5.00,8.13 8.13,5.00 12.00,5.00 Z
 M 18.39,9.14
 C 18.68,9.87 18.87,10.22 18.98,11.18
   19.41,14.77 16.97,16.97 16.97,16.97
   16.97,16.97 15.59,15.59 15.59,15.59
   16.43,14.30 17.00,13.38 17.00,12.00
   17.00,11.55 16.94,11.12 16.83,10.71
   16.83,10.71 18.39,9.14 18.39,9.14 Z
 M 16.24,6.34
 C 16.24,6.34 17.66,7.76 17.66,7.76
   17.66,7.76 13.93,11.48 13.93,11.48
   13.92,11.51 14.32,12.56 13.43,13.48
   12.63,14.20 11.30,14.11 10.64,13.43
   9.70,12.66 10.03,11.23 10.38,10.85
   11.25,9.70 12.45,10.10 12.49,10.10
   12.49,10.12 16.24,6.34 16.24,6.34 Z" />
  </g>
  </g>
`,
];

export default pressure;
