const datalogger = [
  '24 25',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 17.00,12.24
 C 17.55,12.24 18.00,12.70 18.00,13.27
   18.00,13.27 18.00,22.19 18.00,22.19
   18.00,22.19 6.00,22.31 6.00,22.31
   6.00,22.31 6.00,13.27 6.00,13.27
   6.00,12.70 6.45,12.24 7.00,12.24
   7.00,12.24 17.00,12.24 17.00,12.24 Z
 M 9.94,14.31
 C 9.94,14.31 8.00,14.29 8.00,14.29
   8.00,14.29 8.00,16.33 8.00,16.33
   8.00,16.33 10.00,16.33 10.00,16.33
   10.00,16.33 9.94,14.31 9.94,14.31 Z
 M 17.39,9.47
 C 17.39,9.47 15.52,10.51 15.52,10.51
   14.86,9.17 13.47,8.26 11.91,8.26
   10.34,8.26 9.14,9.12 8.48,10.46
   8.48,10.46 6.66,9.62 6.66,9.62
   7.64,7.83 9.37,6.24 12.14,6.24
   14.67,6.19 16.97,8.40 17.39,9.47 Z
 M 11.86,2.20
 C 15.84,2.25 19.30,4.34 20.95,7.68
   20.95,7.68 19.22,8.68 19.22,8.68
   17.91,6.00 15.23,4.27 12.09,4.27
   8.96,4.27 6.24,5.95 4.92,8.63
   4.92,8.63 2.95,7.74 2.95,7.74
   4.72,4.85 7.78,2.25 11.86,2.20 Z" />
  </g>
  </g>
`,
];

export default datalogger;
