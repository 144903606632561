import { configureStore } from '@reduxjs/toolkit';
import appSlice from './appSlice';
import messageSlice from './messageSlice';

export default configureStore({
  reducer: {
    app: appSlice,
    message: messageSlice
  }
});
