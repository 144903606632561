const userManagement = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 20.00,22.00
 C 20.00,22.00 18.00,22.00 18.00,22.00
   18.00,22.00 18.00,20.00 18.00,20.00
   18.00,18.34 16.66,17.00 15.00,17.00
   15.00,17.00 9.00,17.00 9.00,17.00
   7.34,17.00 6.00,18.34 6.00,20.00
   6.00,20.00 6.00,22.00 6.00,22.00
   6.00,22.00 4.00,22.00 4.00,22.00
   4.00,22.00 4.00,20.00 4.00,20.00
   4.00,17.24 6.24,15.00 9.00,15.00
   9.00,15.00 15.00,15.00 15.00,15.00
   17.76,15.00 20.00,17.24 20.00,20.00
   20.00,20.00 20.00,22.00 20.00,22.00 Z
 M 12.00,13.00
 C 8.69,13.00 6.00,10.31 6.00,7.00
   6.00,3.69 8.69,1.00 12.00,1.00
   15.31,1.00 18.00,3.69 18.00,7.00
   18.00,10.31 15.31,13.00 12.00,13.00
   12.00,13.00 12.00,13.00 12.00,13.00 Z
 M 12.00,11.00
 C 14.21,11.00 16.00,9.21 16.00,7.00
   16.00,4.79 14.21,3.00 12.00,3.00
   9.79,3.00 8.00,4.79 8.00,7.00
   8.00,9.21 9.79,11.00 12.00,11.00
   12.00,11.00 12.00,11.00 12.00,11.00 Z" />
  </g>
  </g>
`,
];

export default userManagement;
