const clientsManagement = [
  '24 24',
  `
  <title></title>
  <g>
  <g style="fill:currentColor;">
 <path d="M 21.00,21.00
 C 21.00,21.00 3.00,21.00 3.00,21.00
   2.45,21.00 2.00,20.55 2.00,20.00
   2.00,20.00 2.00,12.49 2.00,12.49
   2.00,12.49 2.00,12.49 2.00,12.49
   2.00,12.19 2.13,11.92 2.34,11.73
   2.34,11.73 6.00,8.54 6.00,8.54
   6.00,8.54 6.00,4.00 6.00,4.00
   6.00,3.45 6.45,3.00 7.00,3.00
   7.00,3.00 21.00,3.00 21.00,3.00
   21.55,3.00 22.00,3.45 22.00,4.00
   22.00,4.00 22.00,20.00 22.00,20.00
   22.00,20.55 21.55,21.00 21.00,21.00
   21.00,21.00 21.00,21.00 21.00,21.00 Z
 M 9.00,19.00
 C 9.00,19.00 12.00,19.00 12.00,19.00
   12.00,19.00 12.00,12.94 12.00,12.94
   12.00,12.94 8.00,9.45 8.00,9.45
   8.00,9.45 4.00,12.94 4.00,12.94
   4.00,12.94 4.00,19.00 4.00,19.00
   4.00,19.00 7.00,19.00 7.00,19.00
   7.00,19.00 7.00,15.00 7.00,15.00
   7.00,15.00 9.00,15.00 9.00,15.00
   9.00,15.00 9.00,19.00 9.00,19.00 Z
 M 14.00,19.00
 C 14.00,19.00 20.00,19.00 20.00,19.00
   20.00,19.00 20.00,5.00 20.00,5.00
   20.00,5.00 8.00,5.00 8.00,5.00
   8.00,5.00 8.00,7.13 8.00,7.13
   8.23,7.13 8.53,7.27 8.53,7.27
   8.53,7.27 13.89,11.94 13.89,11.94
   14.02,12.16 14.00,12.41 14.00,12.41
   13.99,12.16 14.00,19.00 14.00,19.00 Z
 M 16.01,11.02
 C 16.01,11.02 17.98,11.04 17.98,11.04
   17.98,11.04 17.98,12.98 17.98,12.98
   17.98,12.98 16.00,12.98 16.00,12.98
   16.00,12.98 16.01,11.02 16.01,11.02 Z
 M 17.98,14.98
 C 17.98,14.98 16.01,15.02 16.01,15.02
   16.01,15.02 16.01,16.99 16.01,16.99
   16.01,16.99 18.00,16.99 18.00,16.99
   18.00,16.99 17.98,14.98 17.98,14.98 Z
 M 16.00,7.00
 C 16.00,7.00 17.98,7.00 17.98,7.00
   17.98,7.00 17.99,8.98 17.99,8.98
   17.99,8.98 16.00,8.98 16.00,8.98
   16.00,8.98 16.00,7.00 16.00,7.00 Z
 M 12.00,6.98
 C 12.00,6.98 14.02,6.98 14.02,6.98
   14.02,6.98 13.99,8.98 13.99,8.98
   13.99,8.98 12.00,9.00 12.00,9.00
   12.00,9.00 12.00,6.98 12.00,6.98 Z" />
  </g>
  </g>
`,
];

export default clientsManagement;
